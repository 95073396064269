const getThemeVariable = () => {
  const root = document.querySelector(':root')
  if (!root) throw new Error('can not find node with selector :root')
  const rootStyles = getComputedStyle(root)
  return (variable: string) => rootStyles.getPropertyValue(variable)?.trim() || ''
}

const getRootThemeVariables = getThemeVariable()

export { getRootThemeVariables }
