import { getRootThemeVariables } from '../../../helpers'

const xAxis = {
  backgroundLineColor: getRootThemeVariables('--secondary-color'),
  color: getRootThemeVariables('--text-color-secondary'),
}

const yAxis = {
  backgroundLineColor: getRootThemeVariables('--secondary-color'),
  color: getRootThemeVariables('--text-color-secondary'),
}

const series = {
  backgroundLineColor: getRootThemeVariables('--primary-color'),
  itemStyle: {
    borderWidth: 2,
    borderColor: getRootThemeVariables('--text-color-active'),
    color: getRootThemeVariables('--primary-color'),
  },
  markPoint: {
    label: {
      color: getRootThemeVariables('--text-color'),
      textBorderColor: getRootThemeVariables('--secondary-color')
    }
  }
}

const tooltip = {
  backgroundColor: getRootThemeVariables('--primary-color'),
  borderWidth: 0,
  color: getRootThemeVariables('--text-color-active'),
  axisPointer: {
    lineStyle: {
      color: getRootThemeVariables('--primary-color'),
    },
  },
}

export const DEFAULT_LINE_CHART_COLORS_DARK = {
  xAxis,
  yAxis,
  series,
  tooltip,
}
