import { getRootThemeVariables } from '../../../helpers'
import { DEFAULT_COLORS } from './default-colors'

export const DEFAULT_PIE_CHART_COLORS_DARK = () => {
  return {
    stylePreset: {
      pin: {
        dataNormalizer(data: any[]) {
          return data.map((dataItem, position) => {
            const colorDefault = DEFAULT_COLORS[position]
            if (!colorDefault) return dataItem
            return {
              ...dataItem,
              itemStyle: {
                color: dataItem?.color || colorDefault.color,
                shadowColor: dataItem?.color || colorDefault.color,
                shadowBlur: 10,
              },
              label: {
                rich: {
                  b: { borderColor: dataItem?.color || colorDefault.border },
                },
              },
            }
          })
        },
        title: {
          text: (v: string | number) => v,
          left: 'center',
          textStyle: {
            fontFamily: 'Geometria',
            color: getRootThemeVariables('--text-color'),
            fontSize: 40,
          },
          top: 'center',
        },
        labelLine: {
          show: true,
          length: -4.75,
          length2: -2,
        },
        label: {
          formatter: '{b|   {c}    }',
          rich: {
            b: {
              fontSize: 20,
              width: 5,
              height: 5,
              borderRadius: 3,
              backgroundColor: 'black',
              color: getRootThemeVariables('--text-color'),
              borderColor: 'red',
              borderWidth: 3,
            },
          },
        },
      },

      default: {
        label: {
          show: false,
        },
        title: {
          text: (v: string | number) => v,
          left: 'center',
          textStyle: {
            fontFamily: 'Geometria',
            color: getRootThemeVariables('--text-color'),
            fontSize: 40,
          },
          top: 'center',
        },
        labelLine: {
          show: false,
        },
        dataNormalizer(data: any[]) {
          return data.map((dataItem, position) => {
            const colorDefault = DEFAULT_COLORS[position]
            if (!colorDefault) return dataItem
            return {
              ...dataItem,
              itemStyle: {
                color: dataItem?.color || colorDefault.color,
                shadowColor: dataItem?.color || colorDefault.color,
                shadowBlur: 10,
              },
            }
          })
        },
      },
    },
    radius: ['44%', '50%'],
  }
}
