import { getRootThemeVariables } from '../../../helpers'

const xAxis = {
  backgroundLineColor: getRootThemeVariables('--secondary-color'),
  color: getRootThemeVariables('--text-color-secondary'),
}

const yAxis = {
  backgroundLineColor: getRootThemeVariables('--secondary-color'),
  color: getRootThemeVariables('--text-color-secondary'),
}

const series = [
  {
    itemStyle: {
      color: getRootThemeVariables('--secondary-color-dark'),
    },
  },
  {
    itemStyle: {
      color: getRootThemeVariables('--primary-color'),
    },
  },
]

const tooltip = {
  backgroundColor: getRootThemeVariables('--primary-color'),
  borderWidth: 0,
  color: getRootThemeVariables('--text-color-active'),
  axisPointer: {
    lineStyle: {
      color: getRootThemeVariables('--primary-color'),
    },
  },
}

export const DEFAULT_BAR_CHART_COLORS_LIGHT = {
  xAxis,
  yAxis,
  series,
  tooltip,
}
