/** @jsx jsx */
import { jsx } from '@emotion/react'
import { baseStyles } from './styles'
import React, { useEffect, useRef } from 'react'
import { BarChartsOption, Chart, echarts, LineChartsOption, PieChartsOption } from '../../packages'
import ResizeObserver from 'resize-observer-polyfill'
import { throttle } from 'lodash-es'

type PropsMap = {
  options: LineChartsOption | PieChartsOption | BarChartsOption
}

type ExternalApiMap = {}

const BaseChart = React.forwardRef<ExternalApiMap, PropsMap>((props, componentRef) => {
  const { options } = props
  const chartNode = useRef<HTMLDivElement>(null!)
  const chartInstance = useRef<Chart>(null!)

  useEffect(() => {
    const observer = new ResizeObserver(
      throttle(() => {
        chartInstance.current?.resize()
      }, 300),
    )
    observer.observe(chartNode.current)

    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    const { current } = chartNode
    if (chartInstance.current) return chartInstance.current.setOption(options, true)
    chartInstance.current = echarts.init(current)
    chartInstance.current.showLoading()
    chartInstance.current.setOption(options)
    chartInstance.current.hideLoading()
  }, [options])

  return <div ref={chartNode} css={baseStyles} />
})

const BaseChartMemo = React.memo(BaseChart)

export { BaseChartMemo as BaseChart }
