import { colorsBarChartByTheme, BarChartSettings, BarChartYAxisSeriesData } from '../../components'
import { BarChartsOption } from '../../packages'
import { ThemesName } from '../../ts'

type StylesAxis = {
  backgroundColor: string | undefined
  nameTextStyle: { color: undefined | string }
}

export const barChartPropsNormalizer = <T extends {}>(
  settings: BarChartSettings<T>,
  themeName: ThemesName,
): BarChartsOption & {
  xAxis: Partial<StylesAxis>
  yAxis: Partial<StylesAxis>
} => {
  const DEFAULT_LINE_CHART_COLORS = colorsBarChartByTheme[themeName]

  const {
    xAxis: xAxisDefaultColor,
    yAxis: yAxisDefaultColor,
    tooltip: tooltipDefaultColor,
  } = DEFAULT_LINE_CHART_COLORS

  const { xAxis: propsXAxis, yAxis: propsYAxis, tooltip: propsTooltip } = settings
  const { data = [], styles: xAxisStylesProps } = propsXAxis || {}
  const { series = [], styles: yAxisStylesProps } = propsYAxis || {}

  return {
    xAxis: {
      type: 'category',
      backgroundColor:
        xAxisStylesProps?.backgroundLineColor || xAxisDefaultColor.backgroundLineColor,
      nameTextStyle: {
        color: xAxisStylesProps?.textColor || xAxisDefaultColor.color,
      },
      data,
    },
    grid: {
      top: 15,
      right: 15,
      bottom: 25,
      left: 25,
    },
    yAxis: {
      type: 'value',
      splitLine: { show: false },
      axisLine: {
        show: true,
        symbol: ['none', 'arrow'],
      },
      backgroundColor:
        yAxisStylesProps?.backgroundLineColor || yAxisDefaultColor.backgroundLineColor,
      nameTextStyle: {
        color: yAxisStylesProps?.textColor || yAxisDefaultColor.color,
      },
    },
    series: withDefaultSeriesData(series, DEFAULT_LINE_CHART_COLORS),
    tooltip: {
      trigger: propsTooltip?.trigger || 'axis',
      backgroundColor: propsTooltip?.styles?.backgroundColor || tooltipDefaultColor.backgroundColor,
      borderWidth: propsTooltip?.styles?.borderWidth || tooltipDefaultColor.borderWidth,
      textStyle: {
        color: propsTooltip?.styles?.textColor || tooltipDefaultColor.color,
      },
      axisPointer: {
        type: propsTooltip?.axisPointer?.type || 'line',
        lineStyle: {
          color:
            propsTooltip?.axisPointer?.style?.color ||
            tooltipDefaultColor.axisPointer.lineStyle.color,
          type: propsTooltip?.axisPointer?.style?.type || 'solid',
        },
      },
    },
  }
}

const withDefaultSeriesData = <T extends {}>(
  series: BarChartYAxisSeriesData<T>[],
  defaultStyles: typeof colorsBarChartByTheme['dark'],
) =>
  series.map((s, index) => {
    const styles = defaultStyles.series[index]
    return {
      type: s.type || 'bar',
      data: s.data,
      barMaxWidth: 90,
      itemStyle: {
        color: s?.styles?.itemStyle?.color || styles?.itemStyle.color,
      },
    }
  })
