import { getRootThemeVariables } from '../../../helpers'
export const DEFAULT_COLORS = [
  {
    border: getRootThemeVariables('--success-color'),
    color: getRootThemeVariables('--success-color'),
  },
  {
    border: getRootThemeVariables('--error-color'),
    color: getRootThemeVariables('--error-color'),
  },
  {
    border: getRootThemeVariables('--warning-color'),
    color: getRootThemeVariables('--warning-color'),
  },
  {
    border: getRootThemeVariables('--alt-color-3'),
    color: getRootThemeVariables('--alt-color-3'),
  },
  {
    border: getRootThemeVariables('--secondary-color'),
    color: getRootThemeVariables('--secondary-color'),
  },
  {
    border: getRootThemeVariables('--primary-color'),
    color: getRootThemeVariables('--primary-color'),
  },
  {
    border: getRootThemeVariables('--secondary-color-alt-1'),
    color: getRootThemeVariables('--secondary-color-alt-1'),
  },
]
