import * as echarts from 'echarts/core'

import {
  LegendComponent,
  LegendComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  MarkPointComponent,
  MarkPointComponentOption,
  TitleComponent,
  TimelineComponentOption,
  DataZoomComponent,
  DataZoomComponentOption,
  MarkLineComponentOption,
  MarkLineComponent
} from 'echarts/components'

import { UniversalTransition } from 'echarts/features'

import {
  LineChart,
  LineSeriesOption,
  PieChart,
  PieSeriesOption,
  BarChart,
  BarSeriesOption,
} from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

echarts.use([
  LineChart,
  PieChart,
  CanvasRenderer,
  TooltipComponent,
  UniversalTransition,
  LegendComponent,
  MarkPointComponent,
  MarkLineComponent,
  GridComponent,
  TitleComponent,
  BarChart,
  DataZoomComponent,
])

export type LineChartsOption = echarts.ComposeOption<
  | TooltipComponentOption
  | LineSeriesOption
  | LegendComponentOption
  | GridComponentOption
  | DataZoomComponentOption
  | MarkPointComponentOption
  | MarkLineComponentOption
>

export type BarChartsOption = echarts.ComposeOption<
  | TooltipComponentOption
  | BarSeriesOption
  | LegendComponentOption
  | GridComponentOption
  | TimelineComponentOption
>

export type PieChartsOption = echarts.ComposeOption<
  | TooltipComponentOption
  | PieSeriesOption
  | LegendComponentOption
  | GridComponentOption
  | TimelineComponentOption
>

export type Chart = echarts.ECharts
export default echarts
