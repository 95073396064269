import React, { useMemo } from 'react'
import {
  lineChartPropsNormalizer,
  pieChartPropsNormalizer,
  barChartPropsNormalizer,
} from '../helpers'
import { ChartsTypes, ChartSettings } from '../ts'
import { LineChart, PieChart, BarChart } from '.'
import { useThemeName } from '../hooks'

type ChartTypeUnion = keyof typeof ChartsTypes

interface Props<T extends {}> {
  type: ChartTypeUnion
  settings: ChartSettings<T>
}

const propsNormalizerTable: {
  [ChartsTypes.line]: typeof lineChartPropsNormalizer
  [ChartsTypes.bar]: typeof barChartPropsNormalizer
  [ChartsTypes.pie]: typeof pieChartPropsNormalizer
} = {
  line: lineChartPropsNormalizer,
  bar: barChartPropsNormalizer,
  pie: pieChartPropsNormalizer,
}

const chartComponentTable: Record<ChartTypeUnion, (args: any) => JSX.Element> = {
  line: LineChart,
  bar: BarChart,
  pie: PieChart,
}

const ChartsAdapter = <T extends {},>(props: Props<T>) => {
  const { type, settings } = props

  const themeName = useThemeName()

  const { ChartComponent, chartData } = useMemo(
    () => ({
      // @ts-ignore
      chartData: propsNormalizerTable[type](settings, themeName),
      ChartComponent: chartComponentTable[type],
    }),
    [settings, themeName, type],
  )
  return <ChartComponent {...chartData} />
}

const MemoChartAdapter = React.memo(ChartsAdapter)

export { MemoChartAdapter as ChartsAdapter }
