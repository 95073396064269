import { has, isNumber, isObject } from 'lodash-es'
import { PieChartSettings, colorsPieChartByTheme } from '../../components'
import { PieChartsOption } from '../../packages'
import { ThemesName } from '../../ts'

const pieChartPropsNormalizer = <T>(
  settings: PieChartSettings<T>,
  themeName: ThemesName,
): PieChartsOption => {
  const {
    series: { data = [], label, name, radius, labelLine } = {},
    stylePreset: stylePresetType,
    title,
  } = settings
  
  const { radius: defaultRadius, stylePreset } = colorsPieChartByTheme[themeName]()
  const hasPreset = stylePresetType && has(stylePreset, stylePresetType || '')

  const resultSum = data.reduce<number>((acc, item) => {
    const hasValue = isObject(item)  && 'value' in item && isNumber(item.value)
    if (!hasValue) return acc;
    return acc += (item.value as number)
  }, 0)

  return {
    grid: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    title: hasPreset
      ? {
          ...stylePreset[stylePresetType].title,
          text: stylePreset[stylePresetType].title.text(resultSum),
        }
      : title || {},
    tooltip: {},
    series: {
      type: 'pie',
      name,
      emphasis: {
        disabled: true,
      },
      itemStyle: {
        borderRadius: 10,
      },
      radius: radius || defaultRadius,
      data: hasPreset ? stylePreset[stylePresetType].dataNormalizer(data) : data || [],
      labelLine: hasPreset ? stylePreset[stylePresetType].labelLine : labelLine || {},
      label: hasPreset ? stylePreset[stylePresetType].label : label || {},
    },
  }
}

export { pieChartPropsNormalizer }
