import React, { useContext, createContext, useMemo, useState, useEffect } from 'react'
import { IWidgetProps } from './IWidgetProps'
import { ChartsAdapter } from './components'
import { widgetPropsValidator } from './helpers'
import { ChartSettings, ChartsTypes } from './ts'
import { isFunction } from 'lodash-es'

// Mock TODO
// import { lineChartSetting, pieChartSetting, barChartSetting } from './__mock__'

// const mockProps = {
//   props: {
//     chartType: 'line' as const,
//     settings: lineChartSetting,
//     subscribe: (c: any) => {},
//   },
// }

type ChartsAdapterProps<T extends {}> = {
  chartType?: keyof typeof ChartsTypes
  settings?: ChartSettings<T>
}

const widgetPropsContext = <T extends {}>() => createContext<ChartsAdapterProps<T>>(null!)

export function useWidgetProps<T extends {}>() {
  // return mockProps
  return useContext(widgetPropsContext<T>())
}

const WidgetPropsContext = <T extends {}>({
  value,
  children,
}: {
  value: ChartsAdapterProps<T>
  children: any
}) => {
  const Provider = useMemo(() => widgetPropsContext<T>().Provider, [])
  return <Provider value={value}>{children}</Provider>
}

const Root = <T extends {}>(widgetProps: IWidgetProps<T>) => {
  const [props, setProps] = useState<ChartsAdapterProps<T>>({
    chartType: widgetProps.props?.chartType,
    settings: widgetProps.props?.settings,
  })

  const setObservableProps = (data: ChartsAdapterProps<T>) => {
    setProps((prev) => ({ ...prev, ...data }))
  }

  useEffect(() => {
    widgetPropsValidator(props)
  }, [props])

  useEffect(() => {
    if (!widgetProps.props?.subscribe && !isFunction(widgetProps.props?.subscribe)) {
      console.warn('subscribe is not defined')
      return
    }
    return widgetProps.props?.subscribe(setObservableProps)
  }, [widgetProps.props])

  return (
    <WidgetPropsContext<T> value={props}>
      <ChartsAdapter
        settings={props.settings || ({} as ChartSettings<T>)}
        type={props.chartType || 'line'}
      />
    </WidgetPropsContext>
  )
}

export default Root
