import { useEffect, useState } from 'react'
import { ThemesName } from '../ts/enums/theme'

const DEFAULT_THEME = ThemesName.dark
const ROOT_THEME_SELECTOR = '#theme'

const useThemeName = () => {
  const [themeName, setThemeName] = useState<ThemesName>(DEFAULT_THEME)

  useEffect(() => {
    const themeRootNode = document.querySelector(ROOT_THEME_SELECTOR)
    if (!themeRootNode) throw Error(`can not find node with selector ${ROOT_THEME_SELECTOR}`)

    const themeTrigger = (e: CustomEventInit) => setTimeout(() => setThemeName(e.detail), 300)
    const themeName = themeRootNode.getAttribute('theme-name')
    if (themeName) setThemeName(themeName as ThemesName)
    themeRootNode.addEventListener('update', themeTrigger)
    return () => themeRootNode.removeEventListener('update', themeTrigger)
  }, [])

  return themeName
}

export { useThemeName }
