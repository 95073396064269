import { ChartsTypes } from '../../../ts'

export type LineChartYAxisSeriesData<T extends {}> = Partial<{
  [k in keyof T]: T[k]
}> & {
  type: 'line'
  markPoint?: Record<string, any>,
  styles?: {
    backgroundLineColor: string
    itemStyle?: {
      borderWidth: number
      borderColor: string
      color: string
    }
  }
  data: string[] | number[]
}

export interface LineChartSettings<T extends {}> {
  showLabel?: boolean
  visualGridAxis?: boolean
  dataZoom?: {
    xZoom: boolean
    yZoom: boolean
  },
  grid?: {
    top?: number,
    right?: number,
    bottom?: number,
    left?: number,
  },
  xAxis: {
    styles?: {
      backgroundLineColor?: string
      textColor?: string
    }
    data: string[] | number[]
  }
  yAxis: {
    styles?: {
      backgroundLineColor?: string
      textColor?: string
    }
    series: LineChartYAxisSeriesData<T>[]
  }
  tooltip?: {
    trigger: 'axis' | 'none'
    styles?: {
      borderWidth: number
      backgroundColor: string
      textColor: string
    }
    axisPointer?: {
      type: 'line' | 'none'
      style?: {
        color: string
        type?: 'solid' | 'dashed' | 'dotted'
      }
    }
  }
}

export const isLineChartType = (
  chartType: keyof typeof ChartsTypes,
): chartType is ChartsTypes.line => chartType === ChartsTypes.line
