import {
  colorsLineChartByTheme,
  LineChartSettings,
  LineChartYAxisSeriesData,
} from '../../components'
import { LineChartsOption } from '../../packages'
import { ThemesName } from '../../ts'

const dataZoomHandleIcon =
  'image://https://squidex.netvision-internal.ru/api/assets/demo-stand/7b7f6d55-1539-4e34-9a80-68aa68bea1af/netvision-widget-react-chart-zoom-handler.svg?sq=427bb1de-9a3e-7415-1d1c-2fb92325a65a'

type StylesAxis = {
  backgroundColor: string | undefined
  nameTextStyle: { color: undefined | string }
}

export const lineChartPropsNormalizer = <T extends {}>(
  settings: LineChartSettings<T>,
  themeName: ThemesName,
): LineChartsOption & {
  xAxis: Partial<StylesAxis>
  yAxis: Partial<StylesAxis>
} => {
  const DEFAULT_LINE_CHART_COLORS = colorsLineChartByTheme[themeName]

  const {
    xAxis: xAxisDefaultColor,
    yAxis: yAxisDefaultColor,
    tooltip: tooltipDefaultColor,
  } = DEFAULT_LINE_CHART_COLORS

  const {
    xAxis: propsXAxis,
    yAxis: propsYAxis,
    tooltip: propsTooltip,
    dataZoom,
    visualGridAxis,
    showLabel,
    grid
  } = settings
  const { data = [], styles: xAxisStylesProps } = propsXAxis || {}
  const { series = [], styles: yAxisStylesProps } = propsYAxis || {}

  return {
    xAxis: {
      type: 'category',
      axisLabel: {
        show: true,
      },
      backgroundColor:
        xAxisStylesProps?.backgroundLineColor || xAxisDefaultColor.backgroundLineColor,
      nameTextStyle: {
        color: xAxisStylesProps?.textColor || xAxisDefaultColor.color,
      },
      data,
      splitLine: {
        show: visualGridAxis,
        lineStyle: {
          color: ['#3A3C4C'],
          type: 'dashed',
        },
      },
    },
    grid: {
      top: grid?.top || 15,
      right: grid?.right || 15,
      bottom: grid?.bottom || 25,
      left: grid?.left || 25,
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: visualGridAxis,
        lineStyle: {
          color: ['#3A3C4C'],
          type: 'dashed',
        },
      },
      axisLine: {
        show: true,
        symbol: visualGridAxis ? undefined : ['none', 'arrow'],
      },
      backgroundColor:
        yAxisStylesProps?.backgroundLineColor || yAxisDefaultColor.backgroundLineColor,
      nameTextStyle: {
        color: yAxisStylesProps?.textColor || yAxisDefaultColor.color,
      },
    },
    series: withDefaultSeriesData(series, DEFAULT_LINE_CHART_COLORS, showLabel),
    tooltip: {
      className: 'react-line-chart-tooltip',
      trigger: propsTooltip?.trigger || 'axis',
      backgroundColor: propsTooltip?.styles?.backgroundColor || tooltipDefaultColor.backgroundColor,
      borderWidth: propsTooltip?.styles?.borderWidth || tooltipDefaultColor.borderWidth,
      textStyle: {
        color: propsTooltip?.styles?.textColor || tooltipDefaultColor.color,
      },
      axisPointer: {
        type: propsTooltip?.axisPointer?.type || 'line',
        lineStyle: {
          color:
            propsTooltip?.axisPointer?.style?.color ||
            tooltipDefaultColor.axisPointer.lineStyle.color,
          type: propsTooltip?.axisPointer?.style?.type || 'solid',
        },
      },
    },
    dataZoom: dataZoom ? getDataZoom(dataZoom) : undefined,
  }
}

const getDataZoom = (dataZoom: { xZoom: boolean; yZoom: boolean }) => {
  const { xZoom = false, yZoom = false } = dataZoom || {}

  const yZoomData = [
    {
      yAxisIndex: 0,
      type: 'inside',
    },
    {
      type: 'slider',
      yAxisIndex: 0,
      fillerColor: 'transparent',
      borderColor: 'transparent',
      showDataShadow: false,
      brushSelect: false,
      showDetail: false,
      top: 20,
      handleSize: '80%',
      height: '70%',
      left: 20,
      handleIcon: dataZoomHandleIcon,
      moveHandleStyle: {
        color: 'transparent ',
      },
    },
  ]

  const xZoomData = [
    {
      type: 'inside',
    },
    {
      type: 'slider',
      fillerColor: 'transparent',
      borderColor: 'transparent',
      showDataShadow: false,
      brushSelect: false,
      showDetail: false,
      handleSize: '80%',
      width: '95%',
      left: '2.5%',
      handleIcon: dataZoomHandleIcon,
      moveHandleStyle: {
        color: 'transparent ',
      },
    },
  ]

  return ([] as Record<string, any>[]).concat(xZoom ? xZoomData : []).concat(yZoom ? yZoomData : [])
}

const withDefaultSeriesData = <T extends {}>(
  series: LineChartYAxisSeriesData<T>[],
  defaultStyles: typeof colorsLineChartByTheme['dark'],
  showLabel?: boolean,
) =>
  series.map((s) => {
    const styles = defaultStyles.series
    return {
      type: s.type || 'line',
      markPoint: showLabel
        ? {
            symbol: 'pin',
            symbolSize: 27,
            itemStyle: {
              color: 'transparent',
            },
            label: {
              color: defaultStyles.series.markPoint.label.color,
              textBorderColor: defaultStyles.series.markPoint.label.textBorderColor,
              textBorderWidth: 2,
              fontSize: 12,
            },
            data: [
              { type: 'average' as const, name: 'average' },
              { type: 'max' as const, name: 'Max' },
              { type: 'min' as const, name: 'Min' },
            ],
          }
        : undefined,
      smooth: true,
      data: s.data,
      lineStyle: {
        color: s?.styles?.backgroundLineColor || styles.backgroundLineColor,
        shadowColor: s?.styles?.backgroundLineColor || styles.backgroundLineColor,
        shadowBlur: 8,
      },
      symbol: 'circle',
      symbolSize: 8,
      itemStyle: !showLabel
        ? {
            borderWidth: s?.styles?.itemStyle?.borderWidth || styles.itemStyle.borderWidth,
            borderColor: s?.styles?.itemStyle?.borderColor || styles.itemStyle.borderColor,
            color: s?.styles?.itemStyle?.color || styles.itemStyle.color,
          }
        : {
            color: 'transparent',
          },
    }
  })
